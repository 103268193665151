const DAY = 1000 * 60 * 60 * 24;

export class Time {
    /**
     * Format
     */
    static format(year: number, month: number, date: number, delimer = '/'): string {
        return `${year}${delimer}${month > 9 ? '' : '0'}${month}${delimer}${date > 9 ? '' : '0'}${date}`;
    }

    /**
     * Add days to date
     */
    static addDays(date: Date, count: number): Date {
        const value = new Date(date.getTime());
        value.setDate(date.getDate() + count);
        return value;
    }

    /**
     * Remove days from date
     */
    static removeDays(date: Date, count: number): Date {
        const value = new Date(date.getTime());
        value.setDate(date.getDate() - count);
        return value;
    }

    /**
     * Get todays date without time
     */
    static today(): Date {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    /**
     * Get todays date without time
     */
    static now(): Date {
        return new Date();
    }

    /**
     * String to Date convert
     */
    static stringToDate(value: string): Date {
        const [year, month, date] = value.split('-').join('/').split('/');
        return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(date, 10));
    }

    /**
     * String to Date convert
     */
    static stringToTime(value: string): Date {
        const [year, month, date] = value.split(' ')[0].split('-').join('/').split('/');
        const [hour, minute, seconds] = value.split(' ')[1].split(':');
        return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(date, 10), parseInt(hour, 10), parseInt(minute, 10), parseInt(seconds, 10));
    }

    /**
     * Date to String convert
     */
    static dateToString(date: Date, delimer = '/'): string {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return this.format(year, month, day, delimer);
    }

    /**
     * String to String convert - change delimer on the fly
     */
    static stringToString(date: string, delimer = '/'): string {
        return date.split('-').join('%').split('/').join('%').split('%').join(delimer);
    }

    /**
     * Count days between two dates
     */
    static countDays(from: Date, to: Date): number {
        return (to.getTime() - from.getTime() - (to.getTimezoneOffset() - from.getTimezoneOffset()) * 60 * 1000) / DAY + 1;
    }

    /**
     * Get array of string dates between two dates
     */
    static calendar(from: Date, to: Date): string[] {
        const days = this.countDays(from, to);
        return [...Array(days).keys()].map((v, i) => Time.dateToString(Time.addDays(from, i)));
    }

    /**
     * Check if string is a valid date
     */
    static stringIsDate(value: string | null) {
        if (typeof value === 'string') {
            const v = Date.parse(value.split('-').join('/'));
            return !isNaN(v);
        }
        return false;
    }

    /**
     * Check if is Today
     */
    static isToday(date: Date) {
        const today = new Date();
        return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
    }

    /**
     * Check if date1 is greater then date2
     */
    static isGreater(date1: Date, date2: Date) {
        return date1 > date2;
    }
    /**
     * Get year from date
     */
    static getYear(date: string) {
        if (this.stringIsDate(date)) {
            return this.stringToDate(date).getFullYear();
        }
        return 1901;
    }

    static getMonth(date: string) {
        if (this.stringIsDate(date)) {
            return this.stringToDate(date).getMonth();
        }
        return null;
    }
}
